import { NotaryProduct, ShareholderTypes } from '@ligo/bv-flow/store';

import { detailsOfNewCompany, ShareholderForms } from '@ligo/bv-flow/store';

const detailsOfNaturalPerson = [
  ['prefix'],
  ['firstname'],
  ['lastname'],
  ['location'],
  ['residence_country'],
  ['birthdate'],
  ['birthplace'],
  ['country_of_birth'],
  ['email']
];

const detailsOfExistingCompany = [
  ['company_name'],
  ['email'],
  ['company_address'],
  ['company_zipcode'],
  ['company_location'],
  ['chamber'],
  ['prefix'],
  ['firstname'],
  ['lastname'],
  ['location'],
  ['residence_country'],
  ['birthdate'],
  ['birthplace'],
  ['country_of_birth']
];

const detailsOfExistingCompanyKoper = [
  ['company_name'],
  ['email'],
  ['company_address'],
  ['company_zipcode'],
  ['company_location'],
  ['chamber'],
  ['prefix'],
  ['firstname'],
  ['lastname'],
  ['location'],
  ['residence_country'],
  ['birthdate'],
  ['birthplace'],
  ['country_of_birth']
];

const identification = [
  ['document_type'],
  ['document_number'],
  ['issue_date'],
  ['place_of_issue']
];

const otherQuestions = [
  ['lives_in_netherlands'],
  ['english_proficient'],
  ['sole_shareholder'],
  ['become_director'],
  ['seller_resign'],
  ['decharge']
];

const companyOtherQuestions = [
  ['lives_in_netherlands'],
  ['english_proficient']
];

const otherQuestionsKoper = [
  ['lives_in_netherlands'],
  ['english_proficient'],
  ['buyer_sole_shareholder'],
  ['become_director'],
  ['internal']
];

const companyOtherQuestionsKoper = [
  ['lives_in_netherlands'],
  ['english_proficient'],
  ['incorporated_in_netherlands'],
  ['buyer_sole_shareholder'],
  ['internal']
];

const address = [['company_name'], ['email'], ['kvk']];

const activities = [
  ['purchase_agreement'],
  ['purchase_price_received'],
  ['old_acquistion'],
  ['old_acquistion_date'],
  ['received_shares'],
  ['received_shares_date'],
  ['incorporation_date'],
  ['statutes_adopted_date'],
  ['shares_rights_date'],
  ['transfer_rights_date'],
  ['notarial_costs'],
  ['balance_sheet'],
  ['date_of_balance']
];

const company_sections = [address, activities];

const customCompanyFields = {
  birthdate: {
    label: 'transfer_of_shares.shareholder.labels.representative_birthdate'
  },
  birthplace: {
    label: 'transfer_of_shares.shareholder.labels.birthplace'
  }
};

const customKoperCompanyFields = {
  birthdate: {
    label: 'transfer_of_shares.shareholder.labels.representative_birthdate'
  },
  birthplace: {
    label: 'transfer_of_shares.shareholder.labels.birthplace'
  }
};

const customNaturalPersonQuestions = {
  become_director: {
    label: 'transfer_of_shares.shareholder.labels.natural_person_director'
  },
  lives_in_netherlands: {
    label:
      'transfer_of_shares.shareholder.labels.natural_person_lives_in_netherlands',
    labelTooltip:
      'transfer_of_shares.shareholder.label_tooltip.natural_person_lives_in_netherlands'
  },
  english_proficient: {
    label:
      'transfer_of_shares.shareholder.labels.natural_person_english_proficient',
    labelTooltip:
      'transfer_of_shares.shareholder.label_tooltip.natural_person_english_proficient'
  }
};

const customNaturalPersonQuestionsKoper = {
  become_director: {
    label: 'transfer_of_shares.shareholder.labels.koper_natural_person_director'
  },
  lives_in_netherlands: {
    label:
      'transfer_of_shares.shareholder.labels.koper_natural_person_lives_in_netherlands',
    labelTooltip:
      'transfer_of_shares.shareholder.label_tooltip.koper_natural_person_lives_in_netherlands'
  },
  english_proficient: {
    label:
      'transfer_of_shares.shareholder.labels.koper_natural_person_english_proficient',
    labelTooltip:
      'transfer_of_shares.shareholder.label_tooltip.koper_natural_person_english_proficient'
  }
};

const customExistingCompanyKoper = {
  lives_in_netherlands: {
    label: 'transfer_of_shares.shareholder.labels.koper_live_in_netherlands'
  },
  english_proficient: {
    label: 'transfer_of_shares.shareholder.labels.koper_english_proficent'
  }
};

export const useSteps = (notary_product: NotaryProduct) => {
  const shareholders: ShareholderForms = [
    [
      {
        layout: detailsOfNaturalPerson,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      },
      {
        layout: detailsOfExistingCompany,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: {
          6: 'transfer_of_shares.shareholder.labels.company_main_person'
        },
        custom_fields: customCompanyFields
      },
      {
        layout: detailsOfNewCompany,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: {
          4: 'transfer_of_shares.main_person'
        },
        custom_fields: customCompanyFields
      }
    ],
    [
      {
        layout: identification,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      },
      {
        layout: identification,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      },
      {
        layout: identification,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      }
    ],
    [
      {
        layout: otherQuestions,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: customNaturalPersonQuestions
      },
      {
        layout: companyOtherQuestions,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      },
      {
        layout: otherQuestions,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      }
    ]
  ];
  const verkoper: ShareholderForms = [
    shareholders[0],
    shareholders[1],
    shareholders[2]
  ];
  const koper: ShareholderForms = [
    [
      shareholders[0][0],
      {
        layout: detailsOfExistingCompanyKoper,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: {
          6: 'transfer_of_shares.shareholder.labels.company_main_person'
        },
        custom_fields: customKoperCompanyFields
      },
      shareholders[0][2]
    ],
    shareholders[1],
    [
      {
        layout: otherQuestionsKoper,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: customNaturalPersonQuestionsKoper
      },
      {
        layout: companyOtherQuestionsKoper,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: customExistingCompanyKoper
      },
      shareholders[2][2]
    ]
  ];
  const steps = [
    {
      name: 'generic-sections',
      props: {
        locale: 'company_name',
        sections: company_sections,
        resource: 'main'
      }
    },
    {
      name: 'shareholder-information',
      props: {
        shareholderForms: [verkoper, koper],
        enableAdd: false,
        enableDeletable: false,
        singleCardTag: false,
        typeTitles: ['type_title_1', 'type_title_2'],
        cardTagsList: ['Verkoper', 'Koper'],
        setDefaultActives: [1],
        shareholderTypes: [ShareholderTypes.NaturalPerson, ShareholderTypes.ExistingCompany],
        shareholderLen: 2
      }
    },
    { name: 'pie-chart' },
    {
      name: 'contract-upsell'
    },
    { name: 'preview-documents' }
  ];
  return { steps };
};
