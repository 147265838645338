var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_vm._l((_vm.getValidKeys),function(key,i){return _c('div',{key:(key + "-" + i)},[_c('div',{staticClass:"full-width row justify-between"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"q-mb-none font-heading text-default"},[_vm._v(" "+_vm._s(_vm.checkoutCategories[key].labelAppend ? _vm.checkoutCategories[key].labelAppend : _vm.$t(("checkout." + key)))+" ")])]),_c('div',{staticClass:"full-width q-pt-xs"},_vm._l((_vm.sortByFreeItems(
                _vm.checkoutCategories[key].items
              )),function(item,j){return _c('div',{key:j,staticClass:"full-width row justify-between"},[_c('billing-card',{attrs:{"title":_vm.getName(item),"price":item.price,"subtitle":item.itemable && item.itemable.description,"amount":item.amount,"free":item.free,"discount-value":(item.itemable.discounts != null &&
                    item.itemable.discounts.length > 0 &&
                    item.itemable.discounts[0]) ||
                  undefined}})],1)}),0)])])}),_vm._l((_vm.freeItems),function(item,key){return _c('div',{key:("free-line-" + key),staticClass:"full-width row justify-between q-pb-md"},[_c('div',{staticClass:"col-10 font-body text-default"},[(item.name)?_c('p',{staticClass:"q-mb-none"},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(item.description)?_c('p',{staticClass:"q-mb-none text-subdued font-caption q-pt-xs"},[_vm._v(" "+_vm._s(item.description)+" ")]):_vm._e()]),_c('div',{staticClass:"col-2 text-right font-body text-default"},[_vm._v(" "+_vm._s(item.price_tag)+" ")])])}),_vm._m(0),_c('div',{staticClass:"row justify-between font-heading text-default q-mt-md q-mb-sm"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.productLocale(_vm.notaryProduct, 'preview_order.subtotal')))+" ")]),_c('div',[_vm._v("€"+_vm._s((_vm.data.subtotal - _vm.statutoryFeesTotal).toFixed(2)))])]),_vm._l((_vm.statutoryFeesObj.items),function(item,k){return _c('div',{key:("statutory-fees-" + k),staticClass:"full-width row justify-between"},[_c('billing-card',{attrs:{"title":_vm.getName(item),"price":item.price,"subtitle":item.itemable && item.itemable.description,"amount":item.amount,"subtitle-as-tooltip":true,"content-class":'q-mb-xs font-body text-default',"discount-value":(item.itemable.discounts != null &&
              item.itemable.discounts.length > 0 &&
              item.itemable.discounts[0]) ||
            undefined}})],1)}),_c('div',{staticClass:"row full-width justify-between font-body text-default q-pt-xs"},[_c('div',{staticClass:"col-9"},[_c('p',{staticClass:"q-mb-none"},[_vm._v(_vm._s(_vm.$t('btw'))+" ("+_vm._s(_vm.taxAmount)+"%)")])]),_c('div',{staticClass:"text-right font-body text-default col-3"},[_vm._v(" €"+_vm._s(_vm.data.btw.toFixed(2))+" ")])]),_c('div',{staticClass:"row justify-between items-start font-small text-default w-700 q-mt-lg"},[_c('span',[_vm._v(" "+_vm._s(_vm.$t(_vm.productLocale(_vm.notaryProduct, 'preview_order.total')))+" "),_c('p',{staticClass:"q-mb-none font-body text-subdued q-pt-xs"},[_vm._v(" "+_vm._s(_vm.$t('checkout.total_description'))+" ")])]),_c('div',[_vm._v("€"+_vm._s(_vm.data.total.toFixed(2)))])])],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-width"},[_c('div',{staticClass:"full-width q-mb-md calculator-checkout-hr"})])}]

export { render, staticRenderFns }