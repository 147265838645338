





































import { defineComponent, ref, watch } from '@vue/composition-api';
import { PropType } from 'vue';
import { LabelButton, LabelTooltip } from '@ligo/shared/mvc';
import { ShareholderTypes } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'TypeList',
  components: { LabelButton, LabelTooltip },
  props: {
    currentType: {
      type: (String as unknown) as PropType<ShareholderTypes>,
      default: () => ShareholderTypes.NaturalPerson
    },
    shareholderTypes: {
      type: (Array as unknown) as PropType<Array<ShareholderTypes>>,
      default: () => Object.values(ShareholderTypes)
    },
    locale: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'type_title'
    }
  },
  setup(props, { emit }) {
    const activeType = ref(props.currentType);

    function select(key: ShareholderTypes) {
      activeType.value = key;
      emit('selected-type', activeType.value);
    }
    const labelTooltip = ref(false);
    const tooltipText = `${props.locale}natural_person_tooltip`;

    watch(
      () => props.currentType,
      (key) => {
        activeType.value = key;
        select(key);
      }
    );

    return {
      activeType,
      select,
      labelTooltip,
      tooltipText
    };
  }
});
