import { Analytic } from './index';
import Vue from 'vue';
import { CalculatorEvent } from './analytic.service';

export function registerCalculatorEvent(
  event: string,
  calculatorInstance: any,
  keyPassed = false
) {
  if (keyPassed) {
    if (event === 'email') {
      Analytic.registerCalculatorStep(
        CalculatorEvent.COMPLETE_STEP,
        Vue['Store'].state.authentication.accessToken,
        calculatorInstance

        //pending email step
      );
    } else {
      let customEvent = event;
      if (
        event === 'incorporation_speed' &&
        calculatorInstance.incorporation_speed &&
        calculatorInstance.incorporation_speed != 'slow'
      )
        customEvent = 'asap_incorporation';
      Analytic.registerCalculatorStep(
        `notarial calculator - ${customEvent} step`,
        Vue['Store'].state.authentication.accessToken,
        calculatorInstance
      );
    }
  } else {
    Analytic.registerCalculatorStep(
      event,
      Vue['Store'].state.authentication.accessToken,
      calculatorInstance
    );
  }
}
