import { Ref, ref } from '@vue/composition-api';
import {
  NotaryProduct,
  ProductBasicFieldDcl,
  ProductForm
} from '@ligo/bv-flow/store';
import { TypeFields } from './steps/forms_definitions';
import { FormConfig } from '@ligo/shared/mvc';

// Order of enum options is important
export enum ShareholderTypes {
  NaturalPerson = 'natural_person',
  ExistingCompany = 'existing_company',
  NewCompany = 'new_company',
}

export type ShareholderForms = Array<
  Array<
    { [P in keyof ProductForm]?: ProductForm[P] } &
      FormConfig<ProductBasicFieldDcl<any>>
  >
>;

export class Shareholder {
  id: string;
  forms: Array<Array<Ref<ProductForm>>>;
  formGlobal: Ref<ProductForm>;
  stepSideBar: number;
  sideMenuRef: Ref<any> = ref(null as any);
  active: boolean;
  completed: boolean;
  index: number;
  notary_product: NotaryProduct;
  shareholderType: ShareholderTypes;
  typeList: ShareholderTypes[]

  constructor(
    id: string,
    notary_product: NotaryProduct,
    shareholderForms: ShareholderForms,
    typeList: ShareholderTypes[] = Object.values(ShareholderTypes)
  ) {
    this.id = id;
    this.notary_product = notary_product;
    this.typeList = typeList;
    this.forms = shareholderForms.map((row) =>
      row.map((def) => {
        const { layout, ...config } = def;
        return ref(new ProductForm(id, layout, notary_product, config));
      })
    );
    this.formGlobal = ref(
      new ProductForm(id, TypeFields, notary_product, { sendStep: false })
    );
    const resource = this.notary_product.resources[this.id];
    this.stepSideBar = resource.substep;
    this.active = resource.active;
    this.shareholderType = Object.values(ShareholderTypes).find((key) => {
      return this.formGlobal.value.fields[`is_${key}`].value;
    })
    if (this.formGlobal.value.fields.is_natural_person.value === null) {
      this.setType(ShareholderTypes.NaturalPerson);
    }
    this.index = parseInt(this.id.slice(-1));
  }

  setType(key: ShareholderTypes) {
    Object.values(ShareholderTypes).forEach((type) => this.formGlobal.value.fields[`is_${type}`].value = type == key );
    if ([ShareholderTypes.NewCompany, ShareholderTypes.ExistingCompany].includes(key)) {
      this.formGlobal.value.fields['holding_name'].value = null;
      if (key === ShareholderTypes.NewCompany) {
        this.formGlobal.value.fields['holding'].value = true
      }
    }
    this.shareholderType = key;
    void this.formGlobal.value.save(false);
  }

  getMainForm() {
    const index = this.typeList.indexOf(this.shareholderType);
    const form = this.forms[this.stepSideBar][index];
    return form;
  }

  clear() {
    this.active = false;
    this.stepSideBar = 0;
    const resource = this.notary_product.resources[this.id];
    resource.active = false;
    resource.substep = 0;
    void this.notary_product.save(false);
    this.forms.forEach((row) =>
      row.forEach((form) => {
        form.value.reset();
        void form.value.save(false);
      })
    );
    this.formGlobal.value.reset();
    void this.formGlobal.value.save(false);
  }

  save(send = true, sendStep = true) {
    this.notary_product.resources[this.id].active = true;
    this.notary_product.resources[this.id].substep = this.stepSideBar;
    void this.notary_product.save(send, { sendStep: sendStep });
  }
}
