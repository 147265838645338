


























































import { Dictionary, nully, scrollToHtmlElement } from '@ligo/shared/utils';
import { OriginCalculatorType } from '@ligo/bv-flow/store';
import NewContactCard from '../../components/base/NewContactCard.vue';
import {
  computed,
  ComputedRef,
  defineComponent,
  reactive,
  PropType,
  ref
} from '@vue/composition-api';
import CalculatorPriceComponent from '../../components/calculator/CalculatorPriceComponent.vue';
import CalculatorQuestionsCard from '../../components/calculator/CalculatorQuestionsCard.vue';
import StopCard from '../../components/calculator/calculatorQuestions/StopCard.vue';
import QuestionCardForParticipate from '../../components/calculator/calculatorQuestions/QuestionCardForRepresentation.vue';
import QuestionCardForCountries from '../../components/calculator/calculatorQuestions/QuestionCardForCountries.vue';
import QuestionCardForMembership from '../../components/calculator/calculatorQuestions/QuestionCardForMembership.vue';
import QuestionCardForConverting from '../../components/calculator/calculatorQuestions/QuestionCardForConverting.vue';
import { toProductRoute, initializeCalculator } from './calculator.hooks';
import {
  CalculatorEvent,
  registerCalculatorEvent
} from '@ligo/bv-flow/analytics';
import { getRouteNameFromSlug } from 'libs/bv-flow/store/src/lib/models/NotaryProduct';
import { useLocaleTools } from '../../components/layout/locale-hooks';
import { HoldingUpsellPageInformation } from '../../hooks/useBvPageInformation.hooks';
import { SendPositiveToaster } from '../../hooks/useBvNotification.hooks';

const FOCUS_SCROLL_DURATION = 1000;
const TRANSITION_DURATION = 500;

export default defineComponent({
  name: 'Calculator',
  components: {
    CalculatorQuestionsCard,
    QuestionCardForParticipate,
    CalculatorPriceComponent,
    QuestionCardForConverting,
    QuestionCardForCountries,
    QuestionCardForMembership,
    StopCard,
    NewContactCard
  },
  props: {
    uuid: {
      type: String,
      required: false
    },
    origin: {
      type: String as PropType<OriginCalculatorType>,
      default: 'bv-product',
      required: false
    },
    lang: {
      type: String,
      required: false
    }
  },
  setup(props, { root }) {
    const calculator = ref(null);
    const loading = ref(true);
    initializeCalculator(props.uuid).then((response) => {
      calculator.value = reactive(response);
      if (!props.uuid) {
        calculator.value
          .create((props.origin as OriginCalculatorType) || 'bv-product')
          .then(() => {
            root.$router.push({
              name: 'Calculator',
              params: {
                uuid: calculator.value.uuid
              }
            });
          });
        registerCalculatorEvent(
          CalculatorEvent.CALCULATOR_START,
          calculator.value.values
        );
        loading.value = false;
      } else {
        calculator.value.load(props.uuid).then(() => {
          loading.value = false;
        });
      }
    });

    HoldingUpsellPageInformation.load();

    const { changeLanguage } = useLocaleTools(root);

    if (props.lang && props.lang === 'en') changeLanguage('en');

    function onScroll() {
      scrollToHtmlElement(
        `#question-card-${calculator.value.currentQuestion}`,
        FOCUS_SCROLL_DURATION
      );
    }

    function onAnswer(value: Dictionary, key: string, loadingValue = true) {
      loading.value = loadingValue;
      calculator.value
        .onAnswer(key, value)
        .then(() => {
          registerCalculatorEvent(key, calculator.value.values, true);
          loading.value = false;
          if (
            calculator.value.status === 'submitted' &&
            calculator.value.values.email
          ) {
            SendPositiveToaster(root.$calct('email_success') as string);
          }
          onScroll();
        })
        .catch(() => {
          loading.value = false;
        });
    }

    const questions: ComputedRef<string[]> = computed(() => {
      return calculator.value.answered.concat(
        calculator.value.currentQuestion ?? []
      );
    });

    function onContinueToProduct() {
      registerCalculatorEvent(
        CalculatorEvent.CONTINUE_WITH_FLOW,
        calculator.value.values
      );
      if (nully(calculator.notaryProduct)) {
        const route = toProductRoute(calculator.value);
        root.$router.push({
          name: route,
          query: {
            calculationUuid: calculator.value.uuid
          }
        });
      } else {
        const route = getRouteNameFromSlug(
          calculator.value.notaryProduct.notary_product_type_slug
        );
        root.$router.push({
          name: route,
          params: {
            uid: calculator.value.notaryProduct.uuid
          }
        });
      }
    }

    return {
      onAnswer,
      loading,
      questions,
      calculator,
      onContinueToProduct,
      TRANSITION_DURATION
    };
  }
});
