





















import { defineComponent } from '@vue/composition-api';
import NewContactCard from '../components/base/NewContactCard.vue';

export default defineComponent({
  name: 'ServiceUnavailable',
  components: {
    NewContactCard
  }
});
