export default {
  name: 'oprichters',
  title: 'Wie worden de oprichters van',
  description:
    'Onze notarissen vereisen dat een stichting wordt opgericht met minimaal drie bestuurders. Wil je een stichting oprichten met meer dan drie bestuurders? Neem dan gerust even contact op met het legal-support team.',
  opt1: 'Gegevens stichting',
  opt2: 'Gegevens oprichters',
  opt3: 'Identificatie (ID)',
  opt4: 'Overige vragen',
  shareholder: 'Oprichter',
  complete_shareholder:
    'Je dient alle gegevens van de oprichters in te vullen om door te kunnen naar de volgende stap',
  type_title:
    'Richt deze aandeelhouder zelf op (als natuurlijk persoon), met een bestaande holding of met een nieuwe holding?',
  type_message: '',
  cant_incorporate:
    'In dit geval is het helaas niet mogelijk om via Ligo een stichting op te richten.',
  other_services: 'Bekijk andere services',
  natural_person: 'Natuurlijk persoon',
  existing_company: 'Bestaande holding',
  new_company: 'Nieuwe holding',
  no_foreign_legal_entity: 'Oprichtingen met buitenlandse entiteiten zijn niet mogelijk via Ligo. Neem direct contact op met een notaris voor het nodige maatwerk. Ons support-team kan je doorverwijzen: support@ligo.nl of 020 303 1043.',
  natural_person_tooltip:
    'De oprichter van je BV kan een persoon of een bestaande onderneming zijn. Als het een persoon is, kies dan voor natuurlijk persoon. Richt je op met een bestaande onderneming? Kies dan voor bestaande onderneming.'
};
