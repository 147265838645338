














import { defineComponent, PropType, ref } from '@vue/composition-api';
import { AuthError } from '@ligo/bv-flow/store';
import BvAlertBanner from '../../components/base/BvAlertBanner.vue';

export default defineComponent({
  components: { BvAlertBanner },
  props: {
    error: { type: Object as PropType<AuthError> },
    redirectUrl: String
  }
});
