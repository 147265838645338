import {
  CheckoutAPI,
  CheckoutCategories,
  CheckoutLocal,
  checkoutProductForm,
  useNotaryProduct,
  Itemable,
  FreeLineItemModel,
  NotaryProduct
} from '@ligo/bv-flow/store';
import { RootContext } from '@ligo/shared/utils';
import { ProductForm, productLocale } from '@ligo/bv-flow/store';
import { ref } from '@vue/composition-api';
import { goBackAndSync, notaryProductPath } from '../tools/handler-tools';
import Vue from 'vue';
import { analyticGTMCheckout } from '../../hooks/useBvFlowGTM.hooks';
import {
  EXPECTED_PRODUCT_CATEGORY,
  EXPECTED_PRODUCT_TYPE,
  handleMembership
} from '../../components/handlers/shared-steps/ExtraServices/extraService.hooks';

const I18N_FREE_KEY = 'price_estimation.free_line_items';
const TYPE_CONTRACT = 'Contract';
const CATEGORIES_ORDER = ['package', 'services'];
const STATUTORY_FEES_KEYS = 'fees';
const SMART_TAX_EXTRA_SERVICE_SLUG = 'free-intake-smart-tax-accounting';

const getCategoriesObject = (data: Array<Itemable>) =>
  data.reduce((cat: CheckoutCategories, item: Itemable) => {
    if (item.section !== null) {
      if (!(item.section in cat)) cat[item.section] = { items: [], total: 0 };
      const newItem = Object.assign({}, item);
      if (item.itemable.discounts)
        newItem.price += item.itemable.discounts.reduce(
          (sum: number, discount: { discount_amount: number }) => {
            sum -= discount.discount_amount;
            return sum;
          },
          0
        );
      cat[item.section].items.push(newItem);
      cat[item.section].total += newItem.price;
    }
    return cat;
  }, {});

const formatPackages = (
  items: Array<Itemable>,
  root: RootContext,
  packageType: string,
  professionalPlus = 'Plus+'
) => {
  const contract = items.reduce((cont: Itemable, item: Itemable) => {
    if (item.itemable_type === TYPE_CONTRACT) {
      if (Object.keys(cont).length === 0) {
        cont = item;
        if (!cont.itemable.discounts) cont.itemable.discounts = [];
      } else {
        cont.price += item.price;
        if (item.itemable.discounts)
          cont.itemable.discounts = cont.itemable.discounts.concat(
            item.itemable.discounts
          );
      }
    }
    return cont;
  }, {} as Itemable);
  if (Object.keys(contract).length === 0) return items;
  contract.name_nl = root.$t(
    `checkout.contracts.${
      packageType.includes(professionalPlus)
        ? 'professionalPlus'
        : 'professional'
    }`
  );
  return [...items.filter((x) => x.itemable_type !== TYPE_CONTRACT), contract];
};

const serializeAPIResponse = (result: CheckoutAPI, root: RootContext) => {
  const categories: CheckoutCategories = getCategoriesObject(
    result.payment.line_items
  );
  categories.package.items = formatPackages(
    categories.package.items,
    root,
    result.upsell_package_type.name
  );
  categories.package.labelAppend = result.upsell_package_type.name;
  return <CheckoutLocal>{
    name: result.upsell_package_type.name,
    price: result.upsell_package_type.price,
    line_items: categories,
    subtotal: parseFloat(result.payment.payable_price),
    btw:
      result.payment.payment_amount - parseFloat(result.payment.payable_price),
    total: result.payment.payment_amount,
    payment: result.payment
  };
};

const stepValidator = (
  result: CheckoutAPI,
  root: RootContext,
  slug: string,
  uuid: string
) => {
  if (result.payment === undefined)
    root.$router.push(notaryProductPath(slug, uuid));
};

export const useData = (
  uuid: string,
  slug: string,
  root: RootContext,
  handleResult = (_notaryProduct: NotaryProduct) => void {}
) => {
  const loading = ref(true);
  const notaryProduct = ref(useNotaryProduct(slug, uuid));
  const checkoutProductFormRef = ref({} as ProductForm);
  const criminalRecordsLabel = ref('');
  const checkoutData = ref({} as CheckoutLocal);
  const loggedIn = root.$store.state.authentication.accessToken ? true : false;
  notaryProduct.value
    .load(undefined, loggedIn)
    .then(() => {
      stepValidator(notaryProduct.value.upsellPackageData, root, slug, uuid);
      checkoutProductFormRef.value = new ProductForm(
        'main',
        checkoutProductForm,
        notaryProduct.value,
        { sendStep: false }
      );
      criminalRecordsLabel.value = productLocale(
        notaryProduct.value,
        'preview_order.criminal_record_label'
      );
      checkoutData.value = serializeAPIResponse(
        notaryProduct.value.upsellPackageData,
        root
      );
      handleResult(notaryProduct.value);
      checkoutProductFormRef.value.fields.lead_approval.value = false;
      analyticGTMCheckout(
        notaryProduct.value.locale,
        notaryProduct.value.temporalStep,
        notaryProduct.value.slug,
        checkoutData.value.payment.uuid
      );
    })
    .catch((e) => {
      console.log(`There was an error loading the checkout data: ${e.message}`);
    })
    .finally(() => {
      loading.value = false;
    });

  const goBack = () => {
    goBackAndSync(uuid, root, notaryProduct.value);
    root.$router.push(notaryProductPath(slug, uuid));
  };
  return {
    loading,
    checkoutData,
    checkoutProductFormRef,
    criminalRecordsLabel,
    notaryProduct,
    CATEGORIES_ORDER,
    goBack
  };
};

export const useFreeLineItems = (root: any) => {
  const freeItems = ref<FreeLineItemModel[]>([]);

  return {
    freeItems,
    load() {
      Vue['Store']
        .dispatch('pageInformation/loadPage', 'calculator')
        .then(() => {
          const freeLineItemsData = root.$calct(I18N_FREE_KEY);
          freeItems.value = Array.isArray(freeLineItemsData)
            ? freeLineItemsData
            : [];
        });
    }
  };
};

export const useStatutoryFees = (data: CheckoutCategories) => {
  const statutoryFeesObj = data[STATUTORY_FEES_KEYS] || {
    items: [],
    total: 0
  };
  const statutoryFeesTotal = statutoryFeesObj.total / 100;
  return {
    statutoryFeesObj,
    statutoryFeesTotal
  };
};

export const useHasFreeQuotes = () => {
  const hasBookkepper = ref(false);
  const loading = ref(false);

  function load(notaryProduct: NotaryProduct) {
    const { loadMembership } = handleMembership(notaryProduct);

    const selectedPackages: number[] =
      notaryProduct.resources.main?.values?.upsell_package_type_product_ids ||
      [];

    loadMembership()
      .then((packages) => {
        const listSmartTaxtPackages =
          (packages &&
            packages.upsell_package_type_products.filter(
              (item) =>
                item.product_type_type === EXPECTED_PRODUCT_TYPE &&
                item.product_type.category === EXPECTED_PRODUCT_CATEGORY &&
                item.product_type.slug === SMART_TAX_EXTRA_SERVICE_SLUG
            )) ||
          [];
        hasBookkepper.value =
          listSmartTaxtPackages.length > 0 &&
          selectedPackages.includes(listSmartTaxtPackages[0].id);
      })
      .finally(() => {
        loading.value = false;
      });
  }

  return {
    hasBookkepper,
    loading,
    load
  };
};
