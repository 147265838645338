import { SHAREHOLDER_KEYS } from './../../../../../../shared/mvc/src/lib/tools/common';
import { ApiService } from './../../../../../../shared/utils/src/lib/services/api.service';
import { NotaryProductSlug, RESOURCES, Shareholder, ShareholderTypes } from '@ligo/bv-flow/store';
import { BasicFieldType, Resource, ResourceConfig } from '@ligo/shared/mvc';
import {
  CalculatorDcl,
  OriginCalculatorType,
  TransitionCondition
} from './CalculatorField';
import {
  CalculatorDataType,
  CalculatorDclType
} from './CalculatorTypeDefinition';
import {
  insertWithDot,
  nully,
  strictTrue,
  Dictionary,
  isHash,
  isEmptyHash
} from '@ligo/shared/utils';
import { PriceEstimationModel } from './PriceEstimation';

export type CalculatorFieldSet<T> = {
  [P in keyof T]?: CalculatorDcl<T[P]>;
};

export function assert(value, conditionType, match?) {
  switch (conditionType) {
    case 'not-answered':
      return nully(value);
    case 'positive':
      return value;
    case 'negative':
      return value === false;
    case 'equals':
      return value == match;
  }
}

interface NotaryProductInfo {
  notary_product_type_slug: string;
  uuid: string;
}

interface CalculatorResponse {
  uuid: string;
  slug: string;
  status: string;
  origin: OriginCalculatorType;
  payment_estimation: PriceEstimationModel;
  notary_product: NotaryProductInfo;
  data;
}

function cleanValues(hash: Dictionary<any>, dirtyKeys: string[]) {
  Object.keys(hash).forEach((key) => {
    if (nully(hash[key]) || dirtyKeys.includes(key) || isEmptyHash(hash[key]))
      delete hash[key];
    else if (isHash(hash[key])) cleanValues(hash[key], dirtyKeys);
  });
}

export class CalculatorResource extends Resource<CalculatorDataType> {
  uuid: string;
  slug: string;
  fields: CalculatorFieldSet<CalculatorDclType>;
  status: string;
  origin: OriginCalculatorType;
  answered: string[];
  currentQuestion: string;
  notaryProduct: NotaryProductInfo;
  paymentEstimation: PriceEstimationModel;

  constructor(
    resourceConfig: ResourceConfig<CalculatorDataType>,
    locale: string,
    uuid?: string
  ) {
    super(resourceConfig, 'calculator', locale, uuid);
    this.values = {};
    this.fields = resourceConfig.fieldsDescriptions as CalculatorFieldSet<CalculatorDclType>;
    this.answered = [];
  }

  async performLoad(): Promise<void> {
    const response = await ApiService.get<CalculatorResponse>(
      `${RESOURCES.CALCULATION}/${this.uuid}`
    );
    this.paymentEstimation = response.data.payment_estimation;
    this.status = response.data.status;
    this.origin = response.data.origin;
    this.notaryProduct = response.data.notary_product;
    this.updateFromJSON(response.data.data);
    this.initializeOrigin();
    this.loadAnswers();
  }

  async performSave(): Promise<void> {
    const response = await ApiService.patch<{ data: any }, CalculatorResponse>(
      `${RESOURCES.CALCULATION}/${this.uuid}`,
      { data: this.values }
    );
    this.updateFromJSON(response.data.data);
  }

  async create(origin: OriginCalculatorType) {
    const response = await ApiService.post<any, CalculatorResponse>(
      RESOURCES.CALCULATION,
      {
        origin: origin
      }
    );
    this.uuid = response.data.uuid;
    this.origin = response.data.origin;
    this.initializeOrigin();
    this.loadAnswers();
    return response;
  }

  getFields(id?: string) {
    return id ? this.fields[id] : this.fields;
  }

  getValues() {
    return this.values;
  }

  getLocale() {
    return `${this.locale}`;
  }

  updateFromJSON(data: any) {
    const keys = Object.keys(data);
    keys.forEach((key) => {
      if (!nully(data[key])) {
        const valueKey = key.split('.').pop();
        if (!nully(this.fields[valueKey])) {
          const field = this.fields[valueKey] as BasicFieldType;
          const value =
            field.type == 'number' ? data[key].toString() : data[key];
          insertWithDot(this.values, key, value);
        } else if (SHAREHOLDER_KEYS.includes(key) && !nully(data[key])) {
          this.values[key] = data[key];
        }
      }
    });
  }

  linkToContinue() {
    return `questionnaire/calculation/${this.uuid}`;
  }

  nextQuestion(): string {
    return this.nextFieldKey(this.currentQuestion);
  }

  // If the condition is true for at least one shareholder, it will be considered true
  determineCondition(condition: TransitionCondition): boolean {
    const values = Object.values(
      this.getFieldValues(condition.fieldKey)
    ) as any[];
    return values.reduce((result, value) => {
      return result || assert(value, condition.transitionType, condition.value);
    }, false);
  }

  async onAnswer(key: string, data) {
    if (this.answered.includes(key)) {
      const index = this.answered.indexOf(key);
      this.cleanData(this.answered.slice(index + 1));
      this.answered = this.answered.slice(0, index);
      this.status = 'open';
      this.currentQuestion = key;
    }
    this.updateFromJSON(data);
    await this.performSave();
    const nextKey = this.nextQuestion();
    this.answered.push(this.currentQuestion);
    if (nextKey) this.currentQuestion = nextKey;
    else await this.complete();
  }

  async complete() {
    this.currentQuestion = null;
    const response = await ApiService.post<any, CalculatorResponse>(
      `${RESOURCES.CALCULATION}/${this.uuid}/complete`,
      { notary_product_type_slug: this.getNotaryProductTypeSlug() }
    );
    this.origin = response.data.origin;
    this.paymentEstimation = response.data.payment_estimation;
    this.status = response.data.status;
    this.updateFromJSON(response.data.data);
  }

  cleanData(dirtyKeys: string[]) {
    cleanValues(this.values, dirtyKeys);
    Object.keys(this.values).forEach((key) => {
      if (isEmptyHash(this.values[key])) delete this.values[key];
    });
  }

  determineRootKey() {
    return this.getFieldKeys().find((key) => {
      const root = this.fields[key].root;
      return strictTrue(root) || root === this.origin;
    });
  }

  /**
   * Given the transitions of the a question, the next one
   * is determined by the first transition that either has no conditions
   * or all of it's conditions are fulfilled
   */
  nextFieldKey(fieldKey: string): string | undefined {
    const transitions = this.fields[fieldKey].transitions;
    const next = transitions?.find((transition) => {
      return (
        nully(transition.conditions) ||
        transition.conditions.reduce((result, condition) => {
          return result && this.determineCondition(condition);
        }, true)
      );
    });
    return next?.fieldKey;
  }

  getActiveShareholderKeys() {
    const active = this.values['shareholder_count'];
    return active ? SHAREHOLDER_KEYS.slice(0, active) : [];
  }

  getShareholdersCount() {
    return this.values['shareholder_count'];
  }

  getShareholdersData() {
    return this.getActiveShareholderKeys().reduce((result, key) => {
      result[key] = this.values[key];
      return result;
    }, []);
  }

  getShareholdersFor(key: string) {
    const field = this.fields[key];
    const shareHolderKeys = this.getActiveShareholderKeys();
    const shareHolderData = this.getShareholdersData();
    if (field && field.shareholderFilter) {
      const result = shareHolderKeys.filter((shareHolderKey) =>
        field.shareholderFilter.reduce((solve, cond: TransitionCondition) => {
          return (
            solve &&
            assert(
              shareHolderData[shareHolderKey][cond.fieldKey],
              cond.transitionType,
              cond.value
            )
          );
        }, true)
      );
      return result;
    }
    return shareHolderKeys;
  }

  isAnswered(fieldKey: string) {
    const values = Object.values(this.getFieldValues(fieldKey));
    return (
      values.length > 0 &&
      Object.values(values).reduce((result, value) => {
        return result && !nully(value);
      }, true)
    );
  }

  getFieldValues(key: string) {
    const field = this.fields[key];
    if (field.perShareholder) {
      return this.getActiveShareholderKeys().reduce(
        (result, shareholderKey) => {
          const dotKey = `${shareholderKey}.${key}`;
          if (!nully(this.values[shareholderKey]?.[key])) {
            result[dotKey] = this.values[shareholderKey]?.[key];
          }
          return result;
        },
        {}
      );
    } else {
      return { key: this.values[key] };
    }
  }

  trackField(nodeKey: string) {
    if (nodeKey) {
      if (this.isAnswered(nodeKey)) {
        this.answered.push(nodeKey);
        return this.trackField(this.nextFieldKey(nodeKey));
      } else {
        return nodeKey;
      }
    }
  }

  loadAnswers() {
    this.answered = [];
    const root = this.determineRootKey();
    if (this.isAnswered(root)) {
      this.currentQuestion = this.trackField(root);
    } else {
      this.currentQuestion = root;
    }
  }

  initializeOrigin() {
    if (this.origin === 'holding') {
      this.updateFromJSON({
        'p1.representation_type': ShareholderTypes.NaturalPerson,
        shareholder_count: 1
      });
    }
  }

  getNotaryProductTypeSlug() {
    if (this.origin == 'holding') {
      if (!strictTrue(this.values.language_requirement)) {
        return NotaryProductSlug.INCORPORATE_HOLDING;
      }
      return NotaryProductSlug.HOLDING_OPRICHTEN;
    }
    if (strictTrue(this.values.converting)) return NotaryProductSlug.OMZETTING;
    if (strictTrue(this.values.language_requirement))
      return NotaryProductSlug.BV_OPRICHTEN;
    return NotaryProductSlug.INCORPORATE_DUTCH_BV;
  }
}
