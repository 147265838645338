import { BasicFieldType } from '@ligo/shared/mvc';
import { computed, Ref } from '@vue/composition-api';

export const defaultDetailsOfNaturalPersonBase = [
  ['prefix'],
  ['firstname'],
  ['lastname'],
  ['birthdate'],
  ['birthplace'],
  ['country_of_birth'],
  ['email'],
  ['phone_number'],
  ['address'],
  ['zip_code'],
  ['location'],
  ['residence_country'],
  ['marital_status']
];

const defaultDetailsOfNaturalPerson = [
  ...defaultDetailsOfNaturalPersonBase,
  ['has_proprietorship']
];

export const detailsOfNaturalPerson = [
  ...defaultDetailsOfNaturalPerson,
  ['kvk_proprietorship']
];

export const bvDetailsOfNaturalPerson = [
  ...defaultDetailsOfNaturalPerson,
  ['proprietorship_action'],
  ['kvk_proprietorship']
];

export const detailsOfNaturalPersonHolding = [
  ['prefix'],
  ['firstname'],
  ['lastname'],
  ['birthdate'],
  ['birthplace'],
  ['country_of_birth'],
  ['email'],
  ['phone_number'],
  ['address'],
  ['zip_code'],
  ['location'],
  ['residence_country'],
  ['marital_status'],
  ['has_proprietorship'],
  ['kvk_proprietorship']
];

export const detailsOfExistingCompany = [
  ['company_name'],
  ['company_address'],
  ['company_zipcode'],
  ['company_location'],
  ['company_country'],
  ['ubos'],
  ['chamber'],
  ['prefix'],
  ['firstname'],
  ['lastname'],
  ['email'],
  ['phone_number'],
  ['address'],
  ['zip_code'],
  ['location'],
  ['residence_country'],
  ['address_equals_company_address'],
  ['birthdate'],
  ['birthplace'],
  ['country_of_birth'],
  ['marital_status']
];

export const detailsOfNewCompany = [
  ['company_name'],
  ['company_address'],
  ['company_zipcode'],
  ['company_location'],
  ['residence_country'],
  ['prefix'],
  ['firstname'],
  ['lastname'],
  ['email'],
  ['phone_number'],
  ['address'],
  ['zip_code'],
  ['location'],
  ['address_equals_company_address'],
  ['birthdate'],
  ['birthplace'],
  ['country_of_birth'],
  ['marital_status'],
  ['representation_type']
];

export const identification = [
  ['nationality'],
  ['document_number'],
  ['bsn'],
  ['bsn_not_present']
];

export const otherQuestions = [
  ['lives_in_netherlands'],
  ['english_proficient'],
  ['become_director']
];

export const otherQuestionsStichting = [
  ['lives_in_netherlands'],
  ['english_proficient'],
  ['become_director'],
  ['foundation_role']
];

export const otherQuestionsEnglishBV = [
  ['lives_in_netherlands'],
  ['english_proficient'],
  ['become_director']
];

export const companyOtherQUestions = [
  ['lives_in_netherlands'],
  ['english_proficient'],
  ['become_director'],
  ['incorporated_in_netherlands'],
  ['ubo_kvk_registered'],
  ['ubo_kvk_registered_check']
];

export const companyOtherQuestionsEnglishBV = [
  ['english_proficient'],
  ['become_director'],
  ['incorporated_in_netherlands'],
  ['ubo_kvk_registered'],
  ['ubo_kvk_registered_check']
];

export const TypeFields = [
  [
    'is_natural_person',
    'is_existing_company',
    'is_new_company',
    'holding',
    'holding_name'
  ]
];

export const address = [
  ['company_name'],
  ['email'],
  ['industry'],
  ['caregiver'],
  ['big_registration'],
  ['incorporation_speed']
];

export const invoiceDetails = [
  ['name'],
  ['street'],
  ['house_number', 'zip'],
  ['city'],
  ['vat_number'],
  ['phone_number']
];

export const bankruptcyFields = [['bankruptcy'], ['bankruptcy_settled']];

export const checkoutProductForm = [
  ['criminal_record_status', 'lead_approval']
];

export interface PayingForm {
  countryCode: any;
  currencyCode: any;
  merchantAccount: any;
  merchantReference: any;
  merchantSig: any;
  paymentAmount: any;
  sessionValidity: any;
  shipBeforeDate: any;
  shopperEmail: any;
  shopperLocale: any;
  skinCode: any;
  shopperReference?: any;
  recurringContract?: any;
}

const visible_if_false = (field: Ref<BasicFieldType>) => {
  return computed(() => {
    return () => {
      return field.value?.value === false;
    };
  });
};

export const addressCheckboxConfiguration = {
  address: {
    dependency_dcl: {
      fieldParam: 'address_equals_company_address',
      rule: visible_if_false
    }
  },
  location: {
    dependency_dcl: {
      fieldParam: 'address_equals_company_address',
      rule: visible_if_false
    }
  },
  zip_code: {
    dependency_dcl: {
      fieldParam: 'address_equals_company_address',
      rule: visible_if_false
    }
  },
  residence_country: {
    dependency_dcl: {
      fieldParam: 'address_equals_company_address',
      rule: visible_if_false
    }
  }
};
