import { ProductDcl } from './../../product_structure/ProductField';
import { Dictionary } from '@ligo/shared/utils';
import {
  headquartersList,
  sellProductOptions,
  whereSoldOptions,
  financial_year,
  representationOptions,
  industryTypeOptions
} from '../../share';
import { buildRule } from '@ligo/shared/mvc';
import { capitalize } from '../../tools/mainResource';
import { visible_if_answer } from './conditions';
import { INCORPORATION_SPEED } from '../../calculator';

export const commonProductFields: Dictionary<ProductDcl<any>> = {
  email: {
    filter: (value: string) => {
      return value && value.toLowerCase();
    },
    rules: [
      buildRule('email', 'validations.email_format'),
      buildRule('required', 'validations.required_generic')
    ]
  },
  headquarters: {
    type: 'select',
    filterable: true,
    ids: false,
    options: headquartersList
  },
  office_address: { filter: capitalize, filterable: true },
  place_of_business: { filter: capitalize, filterable: true },
  postal_code: {},
  financial_year_end: { type: 'options', options: financial_year, ids: true },
  industry: {
    type: 'select',
    options: industryTypeOptions,
    ids: true,
    filterable: true
  },
  caregiver: {
    type: 'bool-options',
    dependency_dcl: {
      fieldParam: 'industry',
      rule: (field) => visible_if_answer(field, 'health')
    }
  },
  big_registration: {
    type: 'bool-options',
    dependency_dcl: {
      fieldParam: 'industry',
      rule: (field) => visible_if_answer(field, 'health')
    }
  },
  activities: { type: 'textarea' },
  number_of_shares: {
    type: 'number',
    mask: 'integer',
    defaultValue: 120,
    rules: [
      buildRule('positive', 'validations.positive'),
      buildRule('required', 'validations.required_generic')
    ]
  },
  value_of_shares: {
    type: 'number',
    defaultValue: 0.01,
    step: 0.01,
    rules: [
      buildRule('positive', 'validations.positive'),
      buildRule('required', 'validations.required_generic')
    ]
  },
  upsell_package: {},
  upsell_package_type_id: { type: 'number', hidden: true },
  notary_id: {},
  online_notary: { defaultValue: false },
  criminal_record_status: { defaultValue: false },
  bankruptcy: { defaultValue: false, type: 'bool-options' },
  bankruptcy_settled: { type: 'bool-options' },
  lead_approval: { defaultValue: false },
  incorporation_speed: { type: 'select', options: ['medium', 'slow'], ids: true }
};

export const sharedBVProductFields: Dictionary<ProductDcl<any>> = {
  company_name: {
    filter: (value: string) => {
      if (value) return value.replace(/(BV|B.V.)$/g, '').trim();
    },
    rules: [
      buildRule('bv_suffix', 'validations.bv_suffix'),
      buildRule('required', 'validations.required_generic')
    ],
    suffix: ' B.V.'
  },
  upsell_package_type_product_ids: { type: 'multi-select', hidden: true }
};

export const sharedCompanyFields: Dictionary<ProductDcl<any>> = {
  export_products: { type: 'bool-options' },
  import_products: { type: 'bool-options' },
  sell_products: {
    options: sellProductOptions,
    type: 'select',
    ids: true
  },
  where_are_sold: {
    options: whereSoldOptions,
    type: 'multi-select',
    ids: true
  }
};

export const sharedEmployeeFields: Dictionary<ProductDcl<any>> = {
  employees_loan: { type: 'bool-options' },
  full_time_work: {
    type: 'number',
    mask: 'integer',
    rules: [
      buildRule('non_negative', 'validations.non_negative'),
      buildRule('required', 'validations.required_generic')
    ]
  },
  part_time_work: {
    type: 'number',
    mask: 'integer',
    rules: [
      buildRule('non_negative', 'validations.non_negative'),
      buildRule('required', 'validations.required_generic')
    ]
  },
  representation: {
    type: 'select',
    options: representationOptions,
    ids: true
  }
};
