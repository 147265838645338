export default {
  name: 'aandeelhouders',
  title: 'Wie worden de aandeelhouders van',
  description:
    'Vul hier de gegevens in van alle aandeelhouders. Controleer of je de gegevens compleet en juist hebt ingevuld. Zo kunnen wij jou zo snel en goed mogelijk van dienst zijn.',
  opt1: 'Gegevens BV',
  opt2: 'Gegevens aandeelhouder(s)',
  opt3: 'Identificatie (ID)',
  opt4: 'Overige vragen',
  shareholder: 'Aandeelhouder',
  complete_shareholder:
    ' Je dient alle gegevens van de aandeelhouders in te vullen om door te kunnen naar de volgende stap',
  type_title: 'De aandeelhouder is een:',
  type_message:
    'Heeft u ook een persoonlijke holding nodig? Je kunt het opzetten voor € 310 in plaats van € 350.',
  cant_incorporate:
    'In this case, it is unfortunately not possible as an shareholder to set up a BV with Ligo.',
  other_services: 'Check other services'
};
