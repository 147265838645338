const date = new Date();
const this_year = `31-12-${date.getFullYear().toString()}`;
const next_year = `31-12-${(date.getFullYear() + 1).toString()}`;

export default {
  labels: {
    company_name: 'What will be the name of your new BV?',
    headquarters: "Company's registered office",
    office_address: 'Address',
    place_of_business: '',
    postal_code: '',
    activities: 'Describe here the activities of the company',
    sell_products: 'Does the company sell products to consumers or companies?',
    where_are_sold: 'Where are these products sold?',
    export_products: 'Will the company export products and/or services?',
    import_products: 'Will the company import products and/or services?',
    number_of_shares: 'Amount of shares',
    value_of_shares: 'What will be the value of a share?',
    financial_year_end: 'When does the company`s first financial year end?',
    full_time_work:
      'How many people work full-time (15 hours or more per week) at the location?',
    part_time_work:
      'How many people work part-time (15 hours or more per week) at the location?',
    employees_loan: 'Is there incidental loan of employees?',
    representation: 'How can the director represent the BV?',
    custom_notary: 'Notary that will perform the legislation',
    custom_notary_city: 'Your area',
    distribution_of_shares:
      'How will you distribute the share capital of the BV?',
    lead_approval:
      'Would you like to receive 3 free quotes from our selected bookkeepers from your region without obligation?',
    incorporation_speed: 'Would you like to incorporate your business faster?',
  },
  placeholders: {
    headquarters: 'Company Location',
    office_address: 'Enter full address',
    place_of_business: 'Enter full address',
    company_name: 'Type here your company name',
    holding_name: 'Type here your holding name',
    postal_code: 'Enter postal Code',
    website: 'Enter the website address',
    activities: 'Describe about the company....',
    sell_products: 'Select from the list ',
    where_are_sold: 'Select from the list',
    total_shares: 'Total shares',
    value_of_shares: 'Enter value',
    enter_number: 'Enter Number',
    financial_year_end: '',
    full_time_work: 'Enter Number',
    part_time_work: 'Enter Number',
    number_of_shares: 'Enter the number of share',
    value_shares: 'Enter the valuation of share',
    shares: 'Enter number',
    custom_notary:
      'Fill in name, city and phone number of the notary that will perform the legislation',
    custom_notary_city: 'Fill in Information about your area'
  },
  options: {
    sell_products: {
      consumers: 'To consumers',
      companies: 'To companies',
      none: 'Does not apply',
      consumers_and_businesses: 'To both consumers and businesses'
    },
    where_are_sold: {
      store_or_kiosk: 'In a store or kiosk',
      market: 'On the market',
      street_trade: 'Via street trade',
      internet: 'Through Internet',
      from_home: 'From home',
      postorder: 'For postorder',
      not_applicable: 'Does not apply'
    },
    representation: {
      one_signature:
        'The director is independently authorized to represent the BV by himself',
      two_signatures:
        'The director is authorized to represent the BV jointly with a co-director'
    },
    financial_year_end: {
      this_year: this_year,
      next_year: next_year
    },
    incorporation_speed: {
      medium: 'Medium',
      slow: 'Slow'
    }
  }
};
