





















































































import { Notify } from 'quasar';
import { defineComponent, ref } from '@vue/composition-api';
import { buildRule } from '@ligo/shared/mvc';
import { ApiService } from '@ligo/shared/utils';
import SocialCard from './SocialCard.vue';

export default defineComponent({
  name: 'ChangePassword',
  components: { SocialCard },
  props: {
    brand: {
      type: String,
      default: ''
    },
    brandIcon: {
      type: String,
      default: ''
    },
    access_token: {
      type: String
    },
    client: {
      type: String
    },
    expiry: {
      type: String
    },
    uid: {
      type: String
    },
    base: {
      type: String,
      default: ''
    }
  },
  setup(props, { root }) {
    const formRef = ref();
    const loading = ref(false);
    const done = ref(false);

    function onChangePassword() {
      formRef.value.validate().then((valid) => {
        if (valid) {
          loading.value = true;
          ApiService.setHeaders({
            access_token: props.access_token,
            expiry: props.expiry,
            client: props.client,
            uid: props.uid
          } as any);
          ApiService.put(`${props.base}/password`, {
            password: password.value,
            password_confirmation: confirm.value
          })
            .then((response) => {
              loading.value = false;
              Notify.create({
                type: 'positive',
                color: 'primary',
                position: 'top',
                message: response.data['message']
              });
              ApiService.removeHeader();
              root.$router.push({ name: 'Login' });
            })
            .catch((error) => {
              loading.value = false;
              console.log({ error });
              ApiService.removeHeader();
              Notify.create({
                type: 'negative',
                color: 'red',
                position: 'top',
                message:
                  error?.response?.data?.errors[0] ||
                  root.$t('auth.change_password.error')
              });
            });
        }
      });
    }

    const password = ref('');
    const passwordVisibility = ref(false);
    const confirm = ref('');
    const confirmVisibility = ref(false);

    const passwordRules = [
      buildRule('required', 'validations.required_generic'),
      buildRule('password', 'validations.password_length')
    ];
    const confirmRules = [
      buildRule('required', 'validations.required_generic'),
      (v) =>
        v === password.value || root.$t('auth.change_password.confirm_error')
    ];

    return {
      done,
      onChangePassword,
      formRef,
      confirm,
      confirmVisibility,
      password,
      passwordVisibility,
      confirmRules,
      passwordRules,
      loading
    };
  }
});
