














import { defineComponent, ref, computed, PropType } from '@vue/composition-api';
import { useEnvironment } from '../../../../../netlify_functions';
import './froala/plugins/';

export default defineComponent({
  name: 'WYSIWYGEditor',
  props: {
    value: String,
    readonly: {
      type: Boolean,
      default: false
    },
    minHeight: {
      type: Number,
      default: 0
    },
    showToolbarOnFocus: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    border: {
      type: Boolean,
      default: true
    },
    borderedContent: {
      type: Boolean,
      default: false
    },
    padding: {
      type: Boolean,
      default: true
    },
    hideToolbar: {
      type: Boolean,
      default: false
    },
    hideSecondToolbar: {
      type: Boolean,
      default: true
    },
    background: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array as PropType<string[]>,
      default: () => [
        'bold',
        'italic',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'quote',
        'emoticons',
        'insertLink',
        'html'
      ]
    }
  },
  setup(props) {
    const showSeparator = ref(true);

    const editorClass = computed(() => ({
      'basic-container': true,
      'container-non-borders': !props.border,
      'container-non-padding': !props.padding,
      'hide-toolbar': props.hideToolbar,
      'bordered-content': props.borderedContent,
      'hide-second-toolbar': props.hideSecondToolbar,
      'no-background': !props.background
    }));

    const config = {
      toolbarButtons: props.readonly ? [] : props.options,
      codeBeautifierOptions: {
        end_with_newline: true,
        indent_inner_html: true,
        extra_liners: "['p', 'blockquote', 'pre', 'ul', 'ol', 'table', 'dl']",
        brace_style: 'expand',
        indent_char: '\t',
        indent_size: 1,
        wrap_line_length: 0
      },
      events: {
        initialized: function () {
          if (props.showToolbarOnFocus) {
            this.toolbar.hide();
          } else {
            showSeparator.value = false;
          }
          if (props.readonly) {
            this.edit.off();
            const linksArray = document
              .querySelector('.fr-element')
              ?.getElementsByTagName('a');
            if (linksArray && linksArray.length > 0) {
              for (let i = 0; i < linksArray.length; ++i) {
                const linkobj = linksArray[i];
                const link = linkobj.href;
                const id = `link${i}${linkobj.href}`;
                linkobj.setAttribute('id', id);
                document.getElementById(id).addEventListener('click', () => {
                  window.open(link);
                });
              }
            }
          }
        },
        focus: function () {
          if (props.showToolbarOnFocus) {
            this.toolbar.show();
            showSeparator.value = false;
          }
        },
        blur: function () {
          if (props.showToolbarOnFocus) {
            this.toolbar.hide();
            showSeparator.value = true;
          }
        }
      },
      heightMin: props.minHeight,
      attribution: false,
      key: useEnvironment().FROALA_API_KEY,
      placeholderText: props.placeholder
    };

    return { editorClass, config, showSeparator };
  }
});
